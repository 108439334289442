import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

// Styles
import "bootstrap-icons/font/bootstrap-icons.css";
import "scss/index.scss";

// Components
import Router from "components/Router/Router";
import { appSlice } from "store/features/app/slice";

const App: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appSlice.actions.initialize());
  }, [dispatch]);

  return (
    <div className="h-100">
      <Router />
    </div>
  );
};

export default App;
