import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as sitesApi from "api/sites/sitesApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { sitesSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import Site from "types/entities/site/site";

const fetchByIdSuccessHandler = ({ data: entity }: FetchResponse<Site>) =>
  of(
    sitesSlice.actions.fetchByIdSuccess({
      entity,
    })
  );

const fetchByIdErrorHandler = (error: Error) =>
  of(
    sitesSlice.actions.fetchByIdError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message: "Une erreur s'est produite lors de la récupération du site.",
    })
  );

const fetchByIdEpic: Epic = (
  action$: ActionsObservable<ActionType<typeof sitesSlice.actions.fetchById>>,
  state$
) =>
  action$.pipe(
    filter(isOfType(sitesSlice.actions.fetchById.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(sitesApi.fetchById({ id: payload.id, token })).pipe(
        switchMap(fetchByIdSuccessHandler),
        catchError(fetchByIdErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default fetchByIdEpic;
