import { createApi } from "@reduxjs/toolkit/query/react";
import { notificationSlice } from "store/features/notification/slice";
import { baseQueryRTK } from "store/utils/baseQueryRTK";
import { PaginatedResult } from "store/utils/pagination";
import { toUrlEncoded } from "store/utils/toURLEncoded";
import { UserAccount } from "types/entities/user";
import { generateErrorMessage } from "util/generateErrorMessage";

const USER_API_CACHE_KEY = "User";

export const userApi = createApi({
  reducerPath: "UsersApi",
  baseQuery: baseQueryRTK("admin/users"),
  tagTypes: [USER_API_CACHE_KEY],
  endpoints: (builder) => ({
    getUsers: builder.query<
      PaginatedResult<UserAccount>,
      UserAccountSearchParams
    >({
      query: (params) => `?${toUrlEncoded(params)}`,
      onQueryStarted: async (_entity, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            notificationSlice.actions.addErrorNotification({
              message: `Une erreur s'est produite lors de la recherche d'utilisateurs.<p>${generateErrorMessage(
                error
              )}</p>`,
            })
          );
        }
      },
      providesTags: (_result, _error) => [{ type: USER_API_CACHE_KEY }],
    }),
  }),
});

export const { useGetUsersQuery, useLazyGetUsersQuery } = userApi;

export type UserAccountSearchParams = {
  firstName?: string;
  lastName?: string;
  email?: string;
  userExternalId?: string;
  identificationCode?: string;
};
