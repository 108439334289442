import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import Site from "types/entities/site/site";

// Utils
import {
  createBaseReducers,
  createReducer,
  createSearchReducers,
  updateReducer,
} from "store/utils/reducers";
import { updateSuccessState } from "store/utils/stateUpdater";
import { LOCATION_CHANGE } from "connected-react-router";

export const sitesAdapter = createEntityAdapter<Site>();

const { create, update, ...baseReducers } =
  createBaseReducers<Site>(sitesAdapter);

export const sitesSlice = createSlice({
  name: SLICE_NAME,
  initialState: sitesAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    ...baseReducers,
    ...createSearchReducers<Site>(sitesAdapter),
    create: createReducer<Site, { entity: Site }>(),
    update: updateReducer<Site, { entity: Site }>(),
    fetchAllSuccess: (
      state,
      { payload }: PayloadAction<{ count: number; entities: Site[] }>
    ) => {
      state.count = payload.count;

      sitesAdapter.setAll(state, payload.entities);
      updateSuccessState(state);
    },
  },
  extraReducers: {
    [LOCATION_CHANGE]: (state) => {
      state.searchResultsIds = null;
    },
  },
});

export default sitesSlice.reducer;
