import { FC } from "react";
import { Badge } from "react-bootstrap";
const { REACT_APP_API_BASE_URL, REACT_APP_RESTAURATEUR_API_BASE_URL } =
  process.env;

const Env: FC = () => {
  const env = REACT_APP_API_BASE_URL?.includes("localhost")
    ? "local"
    : REACT_APP_API_BASE_URL?.includes("staging")
    ? "staging"
    : "production";

  const restaurateurEnv = REACT_APP_RESTAURATEUR_API_BASE_URL?.includes(
    "localhost"
  )
    ? "local"
    : REACT_APP_RESTAURATEUR_API_BASE_URL?.includes("staging")
    ? "staging"
    : "production";

  return (
    <div className="mr-4">
      <div>
        {env === "local" ? (
          <Badge variant="info">LOCAL</Badge>
        ) : env === "staging" ? (
          <Badge variant="warning">STAGING</Badge>
        ) : (
          <Badge variant="danger">PRODUCTION</Badge>
        )}
      </div>

      {restaurateurEnv !== env && (
        <div>
          <span>/ </span>
          {restaurateurEnv === "local" ? (
            <Badge variant="info">LOCAL</Badge>
          ) : restaurateurEnv === "staging" ? (
            <Badge variant="warning">STAGING</Badge>
          ) : (
            <Badge variant="danger">PRODUCTION</Badge>
          )}
        </div>
      )}
    </div>
  );
};

export default Env;
