import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getApiUrl from "modules/api/getApiUrl";
import { RootState } from "store";
import { currentUserTokenSelector } from "../currentUser/selectors";
import { notificationSlice } from "../notification/slice";
import { generateErrorMessage } from "util/generateErrorMessage";
import ItemType from "types/entities/itemType";

const CACHE_KEY_ITEM_MODELS = "ItemModel" as const;

export const itemModelApi = createApi({
  reducerPath: "itemModelApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl("admin/item-types"),
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = currentUserTokenSelector(state);
      if (token) headers.set("x-auth", `${token}`);
      return headers;
    },
  }),
  tagTypes: [CACHE_KEY_ITEM_MODELS],
  endpoints: (builder) => ({
    getItemModel: builder.query<ItemType, number>({
      query: (itemModelId) => `${itemModelId}`,
      providesTags: (_result, _error, itemModelId) => [
        { type: CACHE_KEY_ITEM_MODELS, id: itemModelId },
      ],
    }),
    getItemModels: builder.query<ItemType[], void>({
      query: () => ({
        url: ``,
      }),
      providesTags: (_result, _error) => [{ type: CACHE_KEY_ITEM_MODELS }],
      onQueryStarted: async (entity, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            notificationSlice.actions.addErrorNotification({
              message: `Une erreur s'est produite lors de la récupération des modèles de contenants.<p>${generateErrorMessage(
                error
              )}</p>`,
            })
          );
        }
      },
    }),
  }),
});
