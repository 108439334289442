import { RootState } from "store";
import getApiUrl from "modules/api/getApiUrl";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

export const baseQueryRTK = (path?: string) =>
  fetchBaseQuery({
    baseUrl: getApiUrl(path ?? ""),
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = currentUserTokenSelector(state);
      if (token) headers.set("x-auth", `${token}`);
      return headers;
    },
  });
