import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getApiUrl from "modules/api/getApiUrl";
import { RootState } from "store";
import { currentUserTokenSelector } from "../currentUser/selectors";
import { notificationSlice } from "../notification/slice";
import { generateErrorMessage } from "util/generateErrorMessage";
import ItemTypeCategory from "types/entities/itemTypeCategory";

const CACHE_KEY_ITEM_CATEGORIES = "itemCategory" as const;

export const itemCategoryApi = createApi({
  reducerPath: "itemCategoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl("admin/item-type-categories"),
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = currentUserTokenSelector(state);
      if (token) headers.set("x-auth", `${token}`);
      return headers;
    },
  }),
  tagTypes: [CACHE_KEY_ITEM_CATEGORIES],
  endpoints: (builder) => ({
    getitemCategory: builder.query<ItemTypeCategory, number>({
      query: (itemCategoryId) => `${itemCategoryId}`,
      providesTags: (_result, _error, itemCategoryId) => [
        { type: CACHE_KEY_ITEM_CATEGORIES, id: itemCategoryId },
      ],
    }),
    getitemCategories: builder.query<ItemTypeCategory[], void>({
      query: () => ({
        url: ``,
      }),
      providesTags: (_result, _error) => [{ type: CACHE_KEY_ITEM_CATEGORIES }],
      onQueryStarted: async (entity, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            notificationSlice.actions.addErrorNotification({
              message: `Une erreur s'est produite lors de la récupération des catégories de contenants.<p>${generateErrorMessage(
                error
              )}</p>`,
            })
          );
        }
      },
    }),
  }),
});
