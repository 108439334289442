import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryRTK } from "store/utils/baseQueryRTK";
import { toUrlEncoded } from "store/utils/toURLEncoded";
import { API_ENDPOINT, CACHE_KEY, REDUCER_PATH } from "./constants";
import { ResetPasswordParams, ResetPasswordResponse } from "./model";

export const resetPasswordAPI = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: baseQueryRTK(),
  tagTypes: [CACHE_KEY],
  endpoints: (builder) => ({
    getResetPassword: builder.query<ResetPasswordResponse, ResetPasswordParams>(
      {
        query: (arg) => `${API_ENDPOINT}?${toUrlEncoded({ ...arg })}`,
        providesTags: [CACHE_KEY],
      }
    ),
  }),
});

export const { useGetResetPasswordQuery } = resetPasswordAPI;
