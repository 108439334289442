import Env from "components/Env/Env";
import Logo from "components/Logo/Logo";
import Nav from "components/Nav/Nav";
import UserNav from "components/UserNav/UserNav";
import { ROUTES_NAME } from "modules/routing/constants";
import getRoutePath from "modules/routing/getRoutePath";
import { FC } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

const PageHeader: FC = () => (
  <Navbar bg="light" className="py-3" expand="lg" fixed="top" variant="light">
    <Container className="d-flex align-items-center">
      <Link title="Home" to={getRoutePath(ROUTES_NAME.HOME)}>
        <Logo className="mr-5" height="30" />
      </Link>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav />
        <UserNav />
      </Navbar.Collapse>
    </Container>
    <Env />
  </Navbar>
);

export default PageHeader;
