import { EntityId } from "@reduxjs/toolkit";

// Constants
import { API_ENDPOINT } from "./constants";

// Types
import Service from "types/entities/site/service";

// Utils
import fetch from "modules/api/fetch";
import getApiUrl from "modules/api/getApiUrl";

const baseUrl = getApiUrl(API_ENDPOINT);

export const fetchAll = (params: { token: string }) =>
  fetch<Service[]>(baseUrl, {
    method: "GET",
    token: params.token,
  });

export const fetchById = (params: { id: EntityId; token: string }) =>
  fetch<Service>(`${baseUrl}/${params.id}`, {
    method: "GET",
    token: params.token,
  });
