import classNames from "classnames";
import { FC, ReactNode } from "react";
import Spinner from "react-bootstrap/Spinner";

type Props = {
  className?: string;
  message?: ReactNode;
};

const Loader: FC<Props> = ({ className, message }) => (
  <div className={classNames("p-5 text-center", className)}>
    <Spinner variant="secondary" animation="border" role="status">
      <span className="sr-only">En cours de chargement</span>
    </Spinner>
    {message && <p className="mt-2">{message}</p>}
  </div>
);

export default Loader;
