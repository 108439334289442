import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getApiUrl from "modules/api/getApiUrl";
import { RootState } from "store";
import { currentUserTokenSelector } from "../../../currentUser/selectors";
import { LessorConfiguration } from "types/entities/site/lessorConfiguration";
import { notificationSlice } from "store/features/notification/slice";
import { generateErrorMessage } from "util/generateErrorMessage";

export const CACHE_KEY_LESSOR_CONFIGURATION = "LessorConfiguration";

// Update lessor configuration types
export interface UpdateLessorAggreementConfigurationParams {
  lessorRef: number;
  lessorType: LessorTypes;
  configuration: LessorConfiguration;
}

export interface UpdateLessorResponse {
  lessorRef: number;
  lessorType: LessorTypes;
  updated: true;
}

export enum LessorTypes {
  PrivateCatering = "PrivateCatering",
  RestaurantNetwork = "RestaurantNetwork",
}

// Get lessor configuration query
export type LessorSearchParams = {
  lessorRef: number;
  lessorType: LessorTypes;
};

export const lessorConfigurationApi = createApi({
  reducerPath: "lessorConfigurationApi",

  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(""),
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = currentUserTokenSelector(state);
      if (token) headers.set("x-auth", `${token}`);
      return headers;
    },
  }),

  tagTypes: [CACHE_KEY_LESSOR_CONFIGURATION],

  endpoints: (builder) => ({
    getLessorConfiguration: builder.query<
      LessorConfiguration | null,
      LessorSearchParams
    >({
      query: ({ lessorRef, lessorType }) => ({
        url: `/leasing/lessors-configurations?lessorRef=${lessorRef}&lessorType=${lessorType}`,
        // We don't want 404 to be considered as error to ensure it is passed to transformResponse function
        validateStatus: (response, _result) =>
          (response.status >= 200 && response.status < 300) ||
          response.status === 404,
      }),
      // If the response is 404, we return null
      transformResponse: (
        response: LessorConfiguration | { statusCode: 404 }
      ) => {
        if ("statusCode" in response) {
          return null;
        } else {
          return response;
        }
      },
      onQueryStarted: async (_entity, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            notificationSlice.actions.addErrorNotification({
              message: `Une erreur s'est produite lors de la récupération de la configuration de contrat.<p>${generateErrorMessage(
                error
              )}</p>`,
            })
          );
        }
      },
      providesTags: (_result, _error, { lessorRef }) => [
        { type: CACHE_KEY_LESSOR_CONFIGURATION, id: lessorRef },
      ],
    }),

    upsertLessorConfiguration: builder.mutation<
      UpdateLessorResponse,
      UpdateLessorAggreementConfigurationParams
    >({
      query: ({ lessorRef, lessorType, configuration }) => ({
        method: "POST",
        url: `/leasing/lessors-configurations?lessorRef=${lessorRef}&lessorType=${lessorType}`,
        body: configuration,
      }),
      onQueryStarted: async (_entity, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            notificationSlice.actions.addErrorNotification({
              message: `Une erreur s'est produite lors de la mise à jour de la configuration de contrat.<p>${generateErrorMessage(
                error
              )}</p>`,
            })
          );
        }
      },
      invalidatesTags: [CACHE_KEY_LESSOR_CONFIGURATION],
    }),
  }),
});

export default lessorConfigurationApi;
